@import "src/assets/styles/variable";
.ant-table-pagination.ant-pagination {
    margin: 8px 0;
  }
  .call-history-popup{
    height: 99vh;
    margin: 0;
    top: 10px;
    left:90px;
 
  .table-call-his {
    height: 86vh;
    width: 100%;
    background-color: $primary-color-block;
    // box-shadow: $primary-box-shadow-block;

    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tbody
      > tr
      > td {
      border-right: 1px solid #f0f0f0 !important;
    }
    .ant-table-tbody > tr > td {
      padding: 4px 4px;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: none;
      &:nth-child(11),
      &:nth-child(12),
      &:nth-child(13) {
        text-align: right;
      }
    }
    .ant-table-body {
      height: calc(86vh - 20px);
    }
    .ant-pagination-options {
      display: none;
    }
  }
}