@import "../../assets/styles/variable.scss";

.container-system-configure {
  position: relative;
  .ant-row {
    width: 100%;
  }
  .row-table {
    margin: 10px;
  }
  .search-form {
    margin: 0 20px;
  }

  .content-wrap {
    padding-inline: 1.25rem;
    background-color: #25476a !important;
    border-radius: 0 0 100% 100%/0 0 3vw 3vw;
    height: 300px;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
  }
  width: 100%;
  .content-top {
    color: #cfd6df;
    .title-text h1 {
      font-size: 2rem;
      font-weight: 400;
      text-transform: capitalize;
      color: #fff;
    }
  }
  .content-grid {
    position: absolute;
    top: 90px;
    height: 800px;
    width: 97.1%;
    margin-inline: 1.25rem;
    background: #fff;
    border-radius: 0.4375rem;
  }
  .top-row-button {
    margin-top: 10px;
    width: 100%;
  }
  .wrap-button {
    display: flex;
    justify-content: flex-end;
    button {
      width: 120px;
      margin-left: 10px;
    }
  }
  .search-form {
    .form-search {
      display: flex;
      .item-control {
        margin-right: 20px;
      }
    }
  }
  .wrap-button-table {
    display: flex;
    justify-content: flex-end;
    button {
      min-width: 90px;
      width: auto;
      margin-left: 10px;
      border-radius: 5px;
    }
  }
  .table-system {
    width: 100%;
    .ant-table-thead {
      border-bottom: 2px solid black !important;
    }
    // .ant-table-pagination.ant-pagination {
    //   display: flex;
    //   justify-content: center;
    // }
  }
}
