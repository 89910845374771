.input-custom{
  .float-label {
    position: relative;
    input{
      height: 40px;
      border: 1px solid #B9B9B9;
      border-radius: 8px;
      background: #FFFFFF;
    }
  }

  .label {
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 11px;
    transition: 0.2s ease all;
  }

  .as-placeholder {
    color: #7D7D7D;
    font-size: 13px;
  }

  .as-label {
    top: -8px;
    font-size: 13px !important;
    background: white;
    padding: 0 4px;
    margin-left: -4px;
  }
  .text-danger{
    color: red;
  }
  .ant-input-disabled{
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    padding-top: 8px;
  }
}




