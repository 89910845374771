@import "../../assets/styles/variable.scss";

#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
  font-weight: 900;
  line-height: 60px;
}
.ant-layout-sider-children .logo {
  display: flex;
  justify-content: center;
}

.icon-call-global {
  position: fixed !important;
}

// .logo {
//   padding: 28px 0 70px;
//   h3 {
//     font-size: 36px;
//     color: #fff;
//     line-height: 1;
//     margin: 0 0 0 auto;
//   }
//   .logo-control{
//     display: flex;
//     width: 100%;
//   }

// }
.custom-themes-light {
  .logo h3 {
    color: #1c1c1c;
  }
}
.ant-layout-sider-collapsed.menu-side-bar {
  max-width: 80px !important;
  .anticon.anticon-menu svg {
    width: 32px;
    height: 23px;
    color: #fff;
  }
}
.ant-layout-sider-collapsed.menu-side-bar.custom-themes-light {
  .anticon.anticon-menu svg {
    color: #1890ff;
  }
}

.content-grid {
  width: 100%;
  position: relative;
  height: 100%;
  background-color: #f1f9fe;
  .wrap-content-common {
    padding: 16px 30px 16px 30px;
    display: flex;
    flex-flow: column;
    height: 100%;
  }
}

.btn-switch {
  position: absolute;
  top: 90px;
  left: 26px;
  background-color: #e5e5e5;
  .ant-switch-handle::before {
    background-color: #135fa6;
  }
}
.btn-switch.ant-switch-checked {
  background-color: #135fa6;
  .ant-switch-handle::before {
    background-color: #e5e5e5;
  }
}

//set tab 100% height
.ant-tabs {
  height: 100%;
  .ant-tabs-content-holder {
    .ant-tabs-content {
      height: 100%;
      .ant-tabs-tabpane {
        height: 100%;
        .ant-row {
          height: 100%;
        }
      }
    }
  }
}

.ant-spin-container {
  height: 100%;
}

.ant-btn {
  // background: $primary-button-color;
  border-radius: 8px;
  margin-left: 8px;
}

.control-button-box {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.search-box {
  background: $primary-color-block;
  box-shadow: $primary-box-shadow-block;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  margin-bottom: 16px;
  .block-control {
    .ant-form-item {
      margin-right: 16px;
    }
  }
}

.left-box {
  margin: 0px 16px 0px 0px;
  background: $primary-color-block;
  box-shadow: $primary-box-shadow-block;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  height: 100%;
}

.middle-box {
  margin: 0px 16px 0px 0px;
  background: $primary-color-block;
  box-shadow: $primary-box-shadow-block;
  border-radius: 8px;
  padding: 24px;
  height: 100%;
}

.right-box {
  margin: 0px 0px 0px 0px;
  background: $primary-color-block;
  box-shadow: $primary-box-shadow-block;
  border-radius: 8px;
  padding: 24px;
  height: 100%;
}

.full-box {
  background: $primary-color-block;
  box-shadow: $primary-box-shadow-block;
  border-radius: 8px;
  padding: 24px;
  height: 100%;
}

.content-box {
  flex-grow: 1;
}

.btn-left {
  margin-right: 8px;
}

.ant-form-item {
  margin: 0px;
}
