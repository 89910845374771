@import "src/assets/styles/variable";

.trip-record-his {
  .form-control-inventory-his {
    .control-history {
      background: $primary-color-block;
      box-shadow: $primary-box-shadow-block;
      border-radius: 8px;
      height: 100%;
      padding: 14px 0;
      padding-bottom: 16px;
      width: 100%;
      .ant-form-item {
        margin: 20px 10px;
      }
      margin-bottom: 20px;
    }
  }
  .ant-table-pagination.ant-pagination {
    margin: 8px 0;
  }
  .table-inventory-his {
    height: 82vh;
    width: 100%;
    margin-top: 20px;
    background-color: $primary-color-block;
    box-shadow: $primary-box-shadow-block;

    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {
      border-right: 1px solid #f0f0f0 !important;
    }
    .ant-table-tbody > tr > td {
      padding: 8px 8px;
      font-size: 12px;
      overflow: hidden;
      font-weight: 500;
      text-overflow: ellipsis;
      text-wrap: none;
      &:nth-child(11),
      &:nth-child(12),
      &:nth-child(13) {
        text-align: right;
      }
    }
    .ant-table-body {
      height: calc(82vh - 40px);
    }
    .ant-pagination-options {
      display: none;
    }
  }
}
