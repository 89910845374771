@import "../../assets/styles/variable.scss";

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  padding-top: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 44px;
  left: -220px;
}
.map {
  height: 100vh;
  width: 100%;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: white;
  border-width: 10px;
  left: 220px;
  margin-left: -10px;
}

.ol-popup:before {
  border-color: rgba(204, 204, 204, 0);
  border-top-color: #cccccc;
  border-width: 11px;
  left: 220px;
  margin-left: -11px;
}

.map-label {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .control-itemTabs {
    display: flex;
    justify-content: flex-end;
  }
}

.popup-list {
  margin: 0px 16px 0px 0px;
  background: $primary-color-block;
  box-shadow: $primary-box-shadow-block;
  border-radius: 8px;
  padding: 24px;
  .title-component {
    margin-bottom: 16px;
  }
  #map-canvas img[src="./Compnents/marker.png#red"] {
    background-color: red;
  }
}

.left-box {
  margin: 0px 16px 0px 0px;
  background: $primary-color-block;
  box-shadow: $primary-box-shadow-block;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  height: 100%;
}

.menu-detail {
  .top-detail {
    margin: 0px 0px 24px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .h3 {
      margin: 0px 0px 0px 0px;
    }
  }
  .form-detail {
    .ant-form-item {
      margin: 0px 0px 16px 0px;
      .ant-form-item-control {
        .ant-form-item-control-input {
          width: 280px;
        }
      }
    }
    .button-save {
      display: flex;
      justify-content: flex-end;
    }
  }
}
