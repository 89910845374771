@import "src/assets/styles/variable";

.table-call-his {
  height: 85vh;
  width: 100%;
  margin-top: 20px;
  background-color: $primary-color-block;
  box-shadow: $primary-box-shadow-block;

  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {
    border-right: 1px solid #f0f0f0 !important;
  }

  .ant-table-tbody > tr > td {
    padding: 8px 8px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: none;
    &:nth-child(11),
    &:nth-child(12),
    &:nth-child(13) {
      text-align: right;
    }
  }
  .ant-table {
    height: calc(85vh - 40px);
  }
  .ant-table-wrapper .ant-table-body {
    height: calc(85vh - 40px);
  }
  .ant-table-container {
    height: calc(85vh - 36px);
  }
  .ant-table-body {
    height: calc(85vh - 80px) !important;
  }
  .ant-pagination-options {
    display: none;
  }

  .ant-table-pagination.ant-pagination {
    margin: 4px 0;
  }
}
