@import "../../../../assets/styles/variable.scss";

.user-form{
  button{
     background: $primary-button-color;
     border-radius: 8px;
  }
  .block-control{
    margin-top: 10px;
    height: 88px;
    background-color: $primary-color-block;
    box-shadow: $primary-box-shadow-block;
    .ant-form-item{
      margin: auto 10px;
    }
  }
}

.container-form-user{
  position: relative;
  width: 100%;
  margin-top: 16px;
  height: 100%;

  .user-list{
    background: $primary-color-block;
    position: absolute;
    box-shadow: $primary-box-shadow-block;
    border-radius: 8px;
    width: 100%;
    max-width: 66%;
    height: 100%;
    .wrap-list{
      position: absolute;
      top: 20px;
      bottom: 20px;
      left: 20px;
      right: 20px;
      .title-component{
        height: 5%;
        margin-top: 10px;
        background-color: $primary-color-block;
        font-size: 20px;
        line-height: 16px;
        letter-spacing: 0.14993999898433685px;
        text-align: left;
      }
      .table-component{
        height: 88%;
        overflow-y: scroll;
      }
    }
  }
  .company-detail{
    background: $primary-color-block;
    position: absolute;
    box-shadow: $primary-box-shadow-block;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    .wrap-detail{
      position: absolute;
      top: 20px;
      bottom: 20px;
      left: 10px;
      right: 10px;
      overflow-y: scroll;
      .button-control{
        float: right;
        .ant-btn{
          height: 40px;
          background: $primary-button-color;
          border-radius: 8px;
          margin-left: 8px;
        }
      }
      .top-detail{
        clear: right;
        display: flex;
        justify-content: space-between;
        height: 5%;
        align-items: center;
        .title-component{
          margin-top: 20px;
          margin-left: 10px;
          background-color: $primary-color-block;
          font-size: 20px;
          line-height: 16px;
          letter-spacing: 0.14993999898433685px;
          text-align: left;
        }
        .detail-control{
          button{
            background: $primary-button-color;
            border-radius: 8px;
            margin-left: 10px;
          }
        }
      }
      .form-detail{
        height: 700px;
        width: 100%;
        margin-top: 13px;
        .ant-form-item{
          margin-bottom: 15px;
          padding-bottom:3px;

        }
      }
    }
  }
}

.info {
  height: 100%;
  width: calc(100% - 90px);
  padding: 0 0 0 10px;

    .row {
      height: 33.33%;
      align-items: center;
      justify-content: flex-start;
    }

    .name {
      color: black;
      font-weight: 500;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .col {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

      .start-date {
        font-size: 12px;
      }

      .number {
        text-align: right;
        font-weight: 500;
        color: #666666;
      }
    }
}
