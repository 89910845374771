@import "src/assets/styles/variable";

.inventory-his {
  .ant-table-pagination.ant-pagination {
    margin: 8px 0;
  }
  .table-inventory-his {
    height: 83vh;
    width: 100%;
    background-color: $primary-color-block;
    box-shadow: $primary-box-shadow-block;

    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {
      border-right: 1px solid #f0f0f0 !important;
    }
    .ant-table-tbody > tr > td {
      padding: 8px 8px;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: none;
      &:nth-child(11),
      &:nth-child(12),
      &:nth-child(13) {
        text-align: right;
      }
    }
    .ant-table-body {
      height: calc(83vh - 20px);
    }
    .ant-pagination-options {
      display: none;
    }
  }
}
