@import "~antd/dist/antd.css";
@import "./assets/styles/variable.scss";

@font-face {
  font-family: "Inter";
  src: url("../src/assets/font/Inter/Inter-VariableFont_slnt\,wght.ttf");
  font-weight: 400;
  font-style: normal;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-size: 100%;
  line-height: 1;
}

//ANT PAGINATION
.ant-pagination {
  justify-content: center;
  .ant-pagination-item {
    background: none;
    border: none;
  }

  .ant-pagination-item-active {
    border: 2px solid #3e88f6;
    border-radius: 5px;

    a {
      font-weight: 600;
    }
  }

  .ant-pagination-prev button,
  .ant-pagination-next button {
    background: none;
    border: none;
  }

  .ant-pagination-prev[aria-disabled="false"] .anticon-left svg path,
  .ant-pagination-next[aria-disabled="false"] .anticon-right svg path {
    fill: #3e88f6;
  }
}

//ANT CHECKBOX
.ant-checkbox-inner {
  padding: 7px;
  border-radius: 2px;
  border: 2px solid #3e88f6;
  background: none;
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background: none;

    &:after {
      position: absolute;
      display: table;
      border: 2.5px solid #3e88f6;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      content: " ";
    }
  }
}

[class^="omi-sdk"] {
  display: none !important;
}
// .omi-sdk-3cn {
//   display: none !important;
// }
// .omi-sdk-wu1 {
//   display: none !important;
// }
// .omi-sdk-q22 {
//   display: none;
// }

// .omi-sdk-a1m {
//   display: none;
// }

// .omi-sdk-f8g {
//   display: none !important;
// }
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #aeaeae;
  border-radius: 3px;
}
svg {
  vertical-align: baseline;
}
