.menu-side-bar {
  flex: content !important;
  max-width: 280px !important;
  // background: linear-gradient(to bottom, #0f943b 0%, #3c915a 52.6%, #94a49a 100%);
  background: linear-gradient(to bottom, #000000 0%, #000000 30.6%, #00bcd4 100%);
  position: relative;
  a {
    font-size: 16px;
    color: #ffffff !important;
    font-weight: bold;
    // font-family: "Poppins", "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto,
    //   "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    //   "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
  .ant-menu-title-content {
    font-size: 16px;
    color: #ffffff !important;
    font-weight: bold;
  }
  .ant-menu {
    background: transparent;
    color: #fff;
    border-right: none;
    a,
    .ant-menu-submenu-title {
      &:hover {
        color: #fff;
      }
    }
    .ant-menu-item {
      margin-bottom: 10px !important;
    }
  }
  .ant-menu-submenu-expand-icon,
  .ant-menu-submenu-arrow {
    color: #fff;
  }
  .img-bottom {
    mix-blend-mode: soft-light;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .btn_toggle {
    // position: absolute;
    // top: 76px;
    // right: -12px;
    margin: 0 12px 0 auto;
    z-index: 99;
    cursor: pointer;
  }
  .ant-menu-sub.ant-menu-inline {
    padding: 0 0 0 42px;
    position: relative;
    div.ant-menu-submenu-title {
      padding-left: 0 !important;
    }
    .ant-menu-sub.ant-menu-inline {
      padding: 0 0 0 10px;
      &:before {
        left: 0;
      }
    }
    &:before {
      content: "";
      width: 1px;
      height: 100%;
      left: 26px;
      top: 0;
      position: absolute;
      background-color: rgba(255, 255, 255, 0.2);
    }
    > li {
      padding: 0 0 0 16px !important;
    }
    .ant-menu-item-selected::after {
      display: none;
    }
  }
  .ant-menu-item {
    &:hover {
      background: rgba(168, 203, 255, 0.4) !important;
      border-radius: 6px;
      box-shadow: none;
      color: #ffff !important;
    }
  }
  .ant-spin-spinning {
    position: absolute;
    left: 50%;
    top: 200px;
    transform: translate(-50%, 0);
    .ant-spin-dot-item {
      background-color: #fff;
    }
  }
  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: transparent !important;
  }
}

.menu-side-bar.custom-themes-light {
  background: linear-gradient(to bottom, #1fd453 0%, #84cd99 52.6%, #e5ece7 100%);
  a {
    color: #1c1c1c;
  }
  .ant-menu {
    color: #1c1c1c;
    a,
    .ant-menu-submenu-title {
      &:hover {
        color: #1c1c1c;
      }
    }
  }
  .ant-menu-submenu-expand-icon,
  .ant-menu-submenu-arrow {
    color: #1c1c1c;
  }
  .ant-menu-light .ant-menu-item {
    &:hover {
      background: rgba(168, 203, 255, 0.4) !important;
      color: #1c1c1c !important;
    }
  }
  .ant-menu-item-icon {
    filter: sepia(500%) hue-rotate(150deg) saturate(5000%) grayscale(0.1) contrast(1) brightness(1) hue-rotate(45deg);
  }
  .ant-spin-spinning {
    .ant-spin-dot-item {
      background-color: #1890ff;
    }
  }
}
.ant-menu-inline .ant-menu-item {
  margin: 0 !important;
}

.ant-menu-item-selected {
  background: rgba(168, 203, 255, 0.4) !important;
  padding: 50px 0 30px;
  border-radius: 6px;
  box-shadow: none;
  color: #ffff !important;
}
.ant-menu-submenu-selected {
  color: #ffff !important;
}
.ant-menu-submenu.ant-menu-submenu-popup {
  > .ant-menu {
    background: #0d599f;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    color: #fff;
    border-radius: 8px;
    > li {
      margin: 0 !important;
      &:hover {
        background: rgba(168, 203, 255, 0.4) !important;
        border-radius: 6px;
        box-shadow: none;
        color: #ffff !important;
      }
    }
  }
  .ant-menu-item a {
    color: #fff;
  }
}
.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-light {
  > .ant-menu {
    background: #e5e5e5;
    color: #1c1c1c;
    > li {
      margin: 0 !important;
      &:hover {
        background: rgba(168, 203, 255, 0.4) !important;
        color: #1c1c1c !important;
      }
    }
  }
  .ant-menu-submenu-title a,
  .ant-menu-item a {
    color: #1c1c1c;
  }
}

.ant-menu-submenu-active > .ant-menu-submenu-title:hover {
  background: rgba(168, 203, 255, 0.4);
  background-size: 20px 20px;
  border-radius: 6px;
  box-shadow: none;
  color: white;
}
