.select-custom{
  .float-label {
  position: relative;
  .ant-select-selector{
    height: 40px;
    border: 1px solid #B9B9B9;
    border-radius: 8px;
    background: #FFFFFF;
    color: #1C1C1C;
  }
}

  .label {
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 11px;
    transition: 0.2s ease all;
  }

  .as-placeholder {
    color: #7D7D7D;
    font-size: 13px;
  }

  .as-label {
    top: -8px;
    font-size: 13px !important;
    background: white;
    padding: 0 4px;
    margin-left: -4px;
    color: #7D7D7D;
  }
  .text-danger{
    color: red;
  }
  .ant-select-selector .ant-select-selection-item{
    padding-top: 5px;
  }
  // .ant-select-focused{
  //   margin-top: 6px;
  // }
  .ant-select:not(.ant-select-disabled) .ant-select-arrow {
    color:#4088f6;
    > span > svg > path{
      stroke-width: 50px;
      stroke: #4088f6;
    }
  }
  .ant-select-disabled .ant-select-arrow {
    display: none;
  }

}
.ant-select-single .ant-select-selector .ant-select-selection-search{
  top: 4px;
}

