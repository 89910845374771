@import "../../assets/styles/variable.scss";

.plan-popup {
  height: 99vh;
  margin: 0;
  top: 10px;
  left: 10px;
  .ant-modal-body {
    height: calc(99vh - 70px);
  }
}

.ant-switch-checked {
  // background-color: red;
}

.ant-switch {
  height: 26px;
  margin-top: 2px;
  .ant-switch-handle {
    margin-top: 1.5px;
    margin-left: 1.5px;
    vertical-align: baseline;
  }
  .ant-switch-inner {
    align-items: center;
  }

  // background-color: red;
}

.left-box-map {
  margin: 0px 16px 0px 0px;
  background: $primary-color-block;
  // box-shadow: $primary-box-shadow-block;
  padding: 4px;
  display: flex;

  .top-detail {
    margin: 0px 0px 24px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .h3 {
      margin: 0px 0px 0px 0px;
    }
  }
  .form-detail {
    .ant-form-item {
      margin: 0px 0px 16px 0px;
      .ant-form-item-control {
        .ant-form-item-control-input {
          width: 280px;
        }
      }
    }
    .button-save {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.right-box-map {
  margin: 0px 0px 0px 0px;
  // background: $primary-color-block;
  // box-shadow: $primary-box-shadow-block;
  // border-radius: 8px;
  width: 100%;
  height: 78vh;
}
