
  .container-login{
  background:#F5F7FA;
  width: 100%;
  min-height: 100vh;
  display: flex;
  font-size:16px;
  // position: relative;
  .wrap-image{
    width: 61%;
    img{
      position: absolute;
      width: 51%;
      height: 66%;
      top:19%;
      left: 7%;
    }
    
  }
  .wrap-login{
    width: 39%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .login{
      width: 75%;
      height: 87%;
      background: #FFFFFF;
      box-shadow: 0px 40px 70px -50px #0A4056;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      position: relative;
      .login-control{
        position: absolute;
        width: 80%;
        margin: auto 0;
        height: 100%;
        .title{
        position: absolute;
        height: 1.75rem;
        width: 100%;
        text-align: center;
        top: 4rem;
        h1{
        height: 1.75rem;
        font-weight: 600;
        font-size: 2.5rem;
        line-height: 1.75rem;
        /* identical to box height, or 40px */
        color: #2F3D4C;
      }
      
      }
      .sub-title{
        position: absolute;
        height: 2rem;
        width: 100%;
        text-align: center;
        top: 5.5rem;
        margin-top: 10px;
        span{
          font-weight: 600;
          line-height: 150%;
          font-size: 1.5rem;
          /* identical to box height, or 36px */
          color: #3F88F7;
        }
      }
      .login-form{
        position: absolute;
        width: 100%;
        height: 30%;
        top:34%;
        .form {
          margin: 0 auto;
          width: 100%;
          input{
            height: 3rem;
            background: #FFFFFF;            
            border-radius: 8px;
          }
          label{
            font-weight: 600;
            font-size: 1rem;
            line-height: 150%;
            /* identical to box height, or 16px */
            color: #2F3D4C;
          }
          .remember{
            font-weight: 400;
            margin-bottom: 5px;
          }
          .alert{
            margin-bottom: 10px;
          }
          .control  span{
              border-radius: 8px;
              padding: 0px 11px;
          }
          .ant-checkbox .ant-checkbox-inner{
             border-radius: 0;
          }
          .ant-alert-message{
            // font-size: 0.75rem;
            padding-bottom: 5px;
          }
          .ant-alert-error{
            height: 2rem;
          }
          //  .password {
          //   margin-bottom: 1rem;
          // }
          button{
            width: 100%;
            height: 2.5rem;
            background: #3F88F7;
            border-radius: 8px;
            span{
              font-family: 'Inter';
              font-size: 1rem;
              font-weight: 600;
              line-height: 1rem;
              letter-spacing: 0em;
            }
          }
          
        }               
      }
      .line{
        position: absolute;
        width: 100%;
        height: 0px;
        top: 89%;
        hr{
        border: 1px solid #3F88F7;
        text-align: center;
        width: 100%;
        }
      }
      .language{
        position:absolute;
        top:91%;
        width: 100%;
        select{
          text-align: center;
          width: 200px;
          border: none;
          font-size: 1rem;
        }
      }
      }
      
    }
  }
}
@media  (min-width:1200px){
  .container-login{
    font-size: 16px;
    .wrap-login{
      .login{
        position: relative;
        .login-control{
          position: absolute;
        
        .title{
          position: absolute;
           top: 10%;
           h1{
              height: 1.75rem;
              font-weight: 600;
              font-size: 2.5rem;
              line-height: 1.75rem;
           }
        }
        .sub-title{
          position: absolute;
          height: 1.25rem;
          top: 13.5%;
          span{
            font-size: 1.5rem;
          }
        }
        .login-form{
            position: absolute;
            top: 30%;
             .form .wrap-control{
              input{
                 height: 3.5rem;
              }
              label{
                font-size: 1rem;
              }
               .ant-alert-message{
            font-size: 0.75rem;
          }
          button{
            height: 3rem;
            border-radius: 8px;
            span{
              font-size: 1rem;
              line-height: 1rem;
            }
          }
             }
        }
        .line{
        position: absolute;
        height: 0px;
        top: 89%;
         .language{
        position:absolute;
        top:91%;
        select{
          font-size: 1rem;
        }
      }
      }
      }
      }
    }
  }
}
