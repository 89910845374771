@import "../../assets/styles/variable.scss";

.wrap-header {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  // background: linear-gradient(to right, #0f943b 0%, #3c915a 52.6%, #94a49a 100%);
  background: linear-gradient(to right, #ffffff 0%, #dfede4 52.6%, #98a39c 100%);
  .wrap-title {
    margin-left: 30px;
    padding-top: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    h1 {
      font-size: 20px;
      font-weight: 600;
      line-height: 16px;
      color: #1c1c1c;
    }
  }
  // .dropdown-btn > .ant-dropdown-trigger {
  //   border: none;
  // }

  // .dropdown-btn > .ant-dropdown-trigger > span {
  //   background-color: white !important;
  // }
  .wrap-user-profile {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-right: 30px;
    .user-wrap {
      display: flex;
      flex-direction: row;
      .user-info {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        font-size: 12px;
      }
      .ant-avatar {
        width: 32px;
        height: 32px;
        margin-right: 10px;
        margin-top: 6px;
      }
      .username {
        font-weight: 600;
        text-shadow: 0 0 currentColor;
      }
      .user-email {
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: 12px;
      }
      .logout {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        background: #f1f9fe;
        border-radius: 8px;
        color: #3f88f6;
        cursor: pointer;
      }
    }
  }
  svg {
    vertical-align: baseline;
  }
}
// .ant-dropdown {
//   background: #3f88f6 !important;
//   // height: 400px;
// }
// .ant-dropdown-button {
//   .ant-btn {
//     background-color: #38a169 !important; // Màu nền chính
//     color: #fff !important; // Màu chữ
//     border: none !important;
//     &:hover {
//       background-color: #2f855a !important; // Màu nền khi hover
//     }
//     &:focus {
//       background-color: #2f855a !important; // Màu nền khi focus
//     }
//   }

//   .ant-btn.ant-btn-icon-only {
//     background-color: #38a169 !important;
//     border: none !important;
//     &:hover {
//       background-color: #2f855a !important;
//     }
//   }
// }
