@import "../../../assets/styles/variable.scss";

.menu-tree {
  flex-direction: column;
  justify-content: space-between;
  .control-tree {
    display: flex;
    justify-content: flex-end;
  }
}

.menu-detail {
  .top-detail {
    margin: 0px 0px 24px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .h3 {
      margin: 0px 0px 0px 0px;
    }
  }
  .form-detail {
    .ant-form-item {
      margin: 0px 0px 16px 0px;
      .ant-form-item-control {
        .ant-form-item-control-input {
          width: 280px;
        }
      }
    }
    .button-save {
    display: flex;
    justify-content: flex-end;
    }
  }
}

.item-tabs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .control-itemTabs {
    display: flex;
    justify-content: flex-end;
  }
}

.popup-list {
  margin: 0px 16px 0px 0px;
  background: $primary-color-block;
  box-shadow: $primary-box-shadow-block;
  border-radius: 8px;
  padding: 24px;
  .title-component {
    margin-bottom: 16px;
  }
}